<template>
  <div id="app">
    <layout-header></layout-header>
    <layout-body></layout-body>
    <layout-footer></layout-footer>
    <login></login>
  </div>
</template>

<script>
import layoutHeader from "@/components/layout/layout-header";
import layoutBody from "@/components/layout/layout-body";
import layoutFooter from "@/components/layout/layout-footer";
import { isMobile } from "@/utils/mobile";
import Login from "@/components/dialog/login";

export default {
  name: 'App',
  components: { Login, layoutHeader, layoutBody, layoutFooter },
  computed: {
    isMobile() {
      return isMobile();
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#app {
  font-family: LarkHackSafariFont, LarkEmojiFont, LarkChineseQuote, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, Segoe UI, PingFang SC, Microsoft Yahei, Hiragino Sans GB, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;;
  color: #545454;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
