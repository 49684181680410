import request from '@/utils/request'

//------------------------文章接口---------------------------

// 网站信息
export function webInfo() {
    return request({
        url: '/common/webInfo',
        method: 'get'
    })
}

// 热门文章
export function hotArticle(limit) {
    return request({
        url: '/api/article/hot',
        method: 'get',
        params: {limit: limit}
    })
}

// 查询通知
export function listNotice(params) {
    return request({
        url: '/api/notice',
        method: 'get',
        params: params
    })
}

// 标签
export function listTag() {
    return request({
        url: '/api/tag',
        method: 'get',
        params: {}
    })
}

// 分类
export function listCategory() {
    return request({
        url: '/api/category',
        method: 'get',
        params: {}
    })
}

// 文章列表
export function listArticle(params) {
    return request({
        url: '/api/article',
        method: 'get',
        params: params
    })
}

// 分类树
export function listCategoryTree() {
    return request({
        url: '/api/category/tree',
        method: 'get',
        params: {}
    })
}


// 友链
export function listFriendChain() {
    return request({
        url: '/api/friendChain',
        method: 'get',
        params: {}
    })
}

// 查询工具
export function listTool(params) {
    return request({
        url: '/api/tool',
        method: 'get',
        params: params
    })
}

// 验证码
export function getCaptcha(file) {
    const formData = new FormData();
    formData.append("file", file);
    return request({
        url: '/common/captcha',
        method: 'get'
    })
}
