<template>
  <div class="layout-body">
    <router-view></router-view>
    <back-top></back-top>
  </div>
</template>

<script>
import BackTop from "@/components/back-top";

export default {
  name: "layout-body",
  components: { BackTop },
}
</script>

<style scoped>
.layout-body {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  /*min-height: -moz-calc(100vh - 60px);*/
  /*min-height: -webkit-calc(100vh - 60px);*/
  /*min-height: calc(100vh - 60px);*/
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(247, 149, 51, .1), rgba(243, 112, 85, .1) 15%, rgba(239, 78, 123, .1) 30%, rgba(161, 102, 171, .1) 44%, rgba(80, 115, 184, .1) 58%, rgba(16, 152, 173, .1) 72%, rgba(7, 179, 155, .1) 100%);
  /*background: url("@/assets/bg.jpg") repeat-y center;*/
  /*background-size: cover;*/
  padding: 1% 0;
}
</style>
