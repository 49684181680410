import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: resolve => require(["@/views/home.vue"], resolve)
    },
    {
        path: '/category',
        component: resolve => require(["@/views/category/category.vue"], resolve)
    },
    {
        path: '/timeAxis',
        component: resolve => require(["@/views/time/time-axis.vue"], resolve)
    },
    {
        path: '/tag',
        component: resolve => require(["@/views/tag/tag.vue"], resolve)
    },
    {
        path: '/source',
        component: resolve => require(["@/views/source/source.vue"], resolve)
    },
    {
        path: '/tool',
        component: resolve => require(["@/views/tool/tool.vue"], resolve)
    },
    {
        path: '/friendChain',
        component: resolve => require(["@/views/chain/friend-chain.vue"], resolve)
    },
    {
        path: '/about',
        component: resolve => require(["@/views/about/about.vue"], resolve)
    },
    {
        path: "/article/:articleId",
        component: resolve => require(["@/views/article/article.vue"], resolve)
    },
    {
        path: "*",
        component: resolve => require(["@/views/404/404.vue"], resolve)
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "route-active",
    // routes: routes,
    routes
});

export default router
