import request from '@/utils/request'

// 登录
export function login(username, password, code, uuid) {
    const data = {
        username: username,
        password: password,
        code: code,
        uuid: uuid
    }
    return request({
        url: '/api/user/login',
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

// 获取个人信息
export function getUserInfo() {
    return request({
        url: '/web/user',
        method: 'get'
    })
}

// 退出
export function logout() {
    return request({
        url: '/web/user/logout',
        method: 'post'
    })
}

// 修改个人信息
export function updateUserInfo(data) {
    return request({
        url: '/web/user',
        method: 'put',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

// 修改密码
export function updatePassword(data) {
    return request({
        url: '/web/user/password',
        method: 'put',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}
