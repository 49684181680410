import axios from 'axios'
import { Message } from 'element-ui'
import { removeLocalUserInfo, removeToken } from "@/utils/auth";

// 创建一个新的axios
const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(config => {
    let token = localStorage.getItem('Authorization');
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(response => {
    const res = response.data;
    if (res.code === 200) {
        return Promise.resolve(res);
    } else {
        if (res.code === 401) {
            removeToken();
            removeLocalUserInfo();
        }
        Message({
            message: res.message,
            type: 'error',
            duration: 3000,
            forbidClick: true
        })
        return Promise.reject(response);
    }
}, error => {
    if (error.code === 'ECONNABORTED') {
        Message({
            message: '接口超时未响应',
            type: 'error',
            duration: 3000,
            forbidClick: true
        })
        return Promise.reject(error);
    }

    let response = error.response;
    if (response) {
        Message({
            message: response.message,
            type: 'error',
            duration: 3000,
            forbidClick: true
        })
    }
    return Promise.reject(error);
});

export default request
