<template>
  <div class="header-user">
    <a class="header-user-tourist" v-if="!this.$store.state.userInfo" @click="showLogin()">
      <img src="@/assets/user.png">
    </a>
    <template v-else>
      <img class="header-user-avatar" :src="this.$store.state.userInfo.avatar"/>
      <ul class="header-user-submenu">
        <li>
          <router-link to="/user/userInfo">
            个人中心
          </router-link>
        </li>
        <li>
          <a @click="logout">退出登录</a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { logout } from "@/api/user";
import {removeLocalUserInfo, removeToken} from "@/utils/auth";

export default {
  name: "header-user",
  data() {
    return {
    }
  },
  watch: {},
  created() {

  },
  methods: {
    showLogin() {
      this.$store.dispatch('loginDialogVisibleAction', true);
    },
    logout() {
      logout().then(res => {
        removeToken();
        removeLocalUserInfo();
      })
    }
  }
}
</script>

<style scoped>
.header-user {
  height: 60px;
}

.header-user-tourist img, .header-user-avatar {
  width: 40px;
  height: 40px;
  margin: 10px 60px 10px 15px;
}

.header-user-avatar {
  cursor: pointer;
  border-radius: 50%;
}

#header-user:hover .header-user-submenu {
  display: block;
}

.header-user-submenu {
  position: absolute;
  display: none;
  right: 0;
  width: 120px;
  height: 65px;
  margin-right: 30px;
  margin-top: 8px;
  box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  animation: submenu 0.3s 0.1s ease both;
}

.header-user-submenu:before {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
}

.header-user-submenu a {
  line-height: 2;
  color: #4c4948 !important;
  text-shadow: none;
  display: block;
  padding-left: 14px;
  text-decoration: none;
}

.header-user-submenu a:hover {
  background: #4ab1f4;
}

ul {
  list-style: none;
}

</style>
