import Vue from 'vue'
import App from './App.vue'

// vue路由
import router from './router'

// store
import store from './store';

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI, {size: 'small', zIndex: 3000});

// mavon-editor
import MavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

Vue.use(MavonEditor);

// icon
import "./assets/css/icon/iconfont.css";

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
