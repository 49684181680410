import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        runTimeInterval: '1686106964966',
        onlinePerson: 0,
        runTimeYear: '2023',
        userInfo: JSON.parse(localStorage.getItem('userInfo')),
        // 登录模态框
        loginDialogVisible: false,
        // 网站信息是否初始化赋值
        webInfoFlag: false,
        categoryNum: 0,
        tagNum: 0,
        articleNum: 0,
        webConfig: {
            createTime: '2023-06-01 12:00:00',
            logo: "",
            name: "强の博客",
            recordNum: "蜀ICP备2022016454号-1",
            touristAvatar: "",
            webUrl: "https://www.oicfx.cn"
        },
        authConfig: {
            aboutMe: '',
            author: "强哥",
            authorAvatar: "",
            authorInfo: '',
            email: "11111111@qq.com",
        }
    },
    getters: {},
    mutations: {
        setLoginDialogVisible: function (state, flag) {
            state.loginDialogVisible = flag;
        },
        setUserInfo: function (state, info) {
            state.userInfo = info;
        },
    },
    actions: {
        loginDialogVisibleAction: function ({commit}, flag) {
            commit('setLoginDialogVisible', flag);
        },
        userInfoAction: function ({commit}, info) {
            commit('setUserInfo', info);
        },
    },
    modules: {}
})
