import Store from "@/store";

const TokenKey = 'Authorization'
const UserInfoKey = 'userInfo'

export function getToken() {
    return localStorage.getItem(TokenKey);
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
    return localStorage.removeItem(TokenKey);
}

export function getLocalUserInfo() {
    return localStorage.getItem(UserInfoKey);
}

export function setLocalUserInfo(userInfo) {
    Store.dispatch("userInfoAction", userInfo);
    return localStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
}

export function removeLocalUserInfo() {
    Store.dispatch("userInfoAction", null);
    return localStorage.removeItem(UserInfoKey);
}
