<template>
  <div>
    <el-drawer
        class="header-menu-app"
        :visible.sync="showAppMenu"
        :withHeader="false"
        :modal="true"
        :size="'100%'"
        :direction="'ltr'"
        :show-close="true">
      <!-- 遍历目录 -->
      <div class="header-menu-top-app">
        <div class="header-menu-logo-app">
          <img src="@/assets/logo.png">
        </div>
        <!-- 统计 -->
        <div class="author-count">
          <div class="author-count-item">
            <router-link to="/timeAxis">文章</router-link>
          </div>
          <div class="author-count-item">
            <router-link to="/category">分类</router-link>
          </div>
          <div class="author-count-item">
            <router-link to="/tag">标签</router-link>
          </div>
        </div>
        <div class="author-count">
          <div class="author-count-item">
            {{ articleNum }}
          </div>
          <div class="author-count-item">
            {{ categoryNum }}
          </div>
          <div class="author-count-item">
            {{ tagNum }}
          </div>
        </div>
      </div>
      <div class="header-menu-item-app" v-for="(item, index) in menus" :key="index">
        <router-link :to="item.router"><i :class="'iconfont ' + item.icon"/>&nbsp;{{ item.name }}</router-link>
      </div>
      <div v-if="!this.$store.state.userInfo" class="header-menu-item-app">
        <span @click='showLogin()'><i class="iconfont icon-denglu"/>&nbsp;登录</span>
      </div>
      <div v-if="this.$store.state.userInfo" class="header-menu-item-app">
        <router-link to="/userInfo">
          <img :src="this.$store.state.userInfo.avatar"
               style="width: 16px;height: 16px;border-radius: 8px;border: #aaaaaa solid 1px;">&nbsp;个人中心
        </router-link>
      </div>
      <div v-if="this.$store.state.userInfo" class="header-menu-item-app">
        <p @click="logout()">
          <i class="iconfont icon-tuichudenglu"/>&nbsp;退出
        </p>
      </div>
    </el-drawer>

    <div v-if="isMobile" class="layout-header">
      <div class="header-site-app">
        <router-link to="/" class="header-site-logo-app">
          <img src="@/assets/logo.png" alt="">
          <span class="header-site-name">
            强哥博客
          </span>
        </router-link>
      </div>
      <div class="header-search-app">
        <i class="iconfont icon-sousuo"></i>
      </div>
      <div class="header-menu-icon-app">
        <i class="iconfont icon-ai-kind" @click.stop="appMenuShow()"></i>
      </div>
    </div>
    <div v-if="!isMobile" class="layout-header">
      <div class="header-site">
        <router-link to="/" class="header-site-logo">
          <img src="@/assets/logo.png" alt="">
          <span class="header-site-name">
            强哥博客
          </span>
        </router-link>
      </div>
      <div class="header-menu">
        <div class="header-menu-item" v-for="(item, index) in menus" :key="index">
          <router-link :to="item.router"><i :class="'iconfont ' + item.icon"/>&nbsp;{{ item.name }}
          </router-link>
        </div>
      </div>
      <div class="header-right">
        <header-search v-if="showSearch"></header-search>
        <header-user></header-user>
      </div>
    </div>
  </div>
</template>

<script>
import {isMobile} from "@/utils/mobile";
import HeaderSearch from "@/components/header/header-search";
import HeaderUser from "@/components/header/header-user";
import {logout} from "@/api/user";
import {removeLocalUserInfo, removeToken} from "@/utils/auth";

export default {
  name: "layout-header",
  components: {HeaderUser, HeaderSearch},
  data() {
    return {
      articleNum: 1,
      categoryNum: 1,
      tagNum: 1,
      isMobile: isMobile(),
      showAppMenu: false,
      showSearch: false,
      windowWidth: document.body.clientWidth,
      menus: [
        {name: '首页', router: '/', icon: 'icon-shouye'},
        {name: '分类', router: '/category', icon: 'icon-ai-kind'},
        {name: '时间轴', router: '/timeAxis', icon: 'icon-shijian1'},
        {name: '标签', router: '/tag', icon: 'icon-24gf-tags2'},
        {name: '源码', router: '/source', icon: 'icon-a-27ruanjian'},
        {name: '工具包', router: '/tool', icon: 'icon-gongju1'},
        {name: '友链', router: '/friendChain', icon: 'icon-lianjie2'},
        {name: '关于', router: '/about', icon: 'icon-guanyu1'}
      ]
    };
  },
  created() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    }, true)
  },
  watch: {
    // 监听页面宽度
    windowWidth() {
      this.isMobile = isMobile();
      this.showAppMenu = this.isMobile && this.showAppMenu;
      this.showSearch = this.windowWidth > 1350;
    }
  },
  mounted() {
    document.addEventListener('click', this.appMenuHidden)
  },
  methods: {
    appMenuShow() {
      this.showAppMenu = this.isMobile && true;
    },
    appMenuHidden() {
      this.showAppMenu = this.isMobile && false;
    },
    showLogin() {
      this.$store.dispatch('loginDialogVisibleAction', true);
    },
    logout() {
      logout().then(res => {
        removeToken();
        removeLocalUserInfo();
      })
    }
  }
}
</script>

<style scoped>
.iconfont {
  font-size: 14px;
}

/** 整个头部样式 **/
.layout-header {
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  background-color: hsla(0, 0%, 100%, .9);
  line-height: 60px;
  position: fixed;
  top: 0;
  z-index: 2100;
  justify-content: space-between;
  align-items: center;
  transition: .3s all ease;
  -webkit-transition: .3s all ease;
  -moz-transition: .3s all linear;
  -o-transition: .3s all ease;
  -ms-transition: .3s all ease;
}

/**app菜单**/
.header-menu-app {
  float: left;
  left: 0;
  width: 260px;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  z-index: 1550;
}

.header-menu-top-app {
  background-color: #997265;
}

.header-menu-logo-app {
  width: 100%;
  height: 70px;
  text-align: center;
}

.header-menu-logo-app img {
  margin-top: 15px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.header-menu-item-app {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  padding-left: 80px;
  text-align: left;
  position: relative;
  border-bottom: 1px dashed gray;
}

.header-menu-item-app a {
  font-weight: 500;
  font-size: 14px;
  color: #545454;
}

/** 头部logo样式 **/
.header-site {
  width: 20%;
  min-width: 200px;
  text-align: center;
  position: relative;
}

.header-menu-icon-app {
  width: 10%;
}

.header-site-app {
  width: 40%;
  height: 100%;
}

.header-site-logo img {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 15px;
}

.header-site-logo-app img {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.header-site-name {
  vertical-align: middle;
  font-size: 17px;
  font-weight: bold;
  color: #545454;
}

/** 头部菜单样式 **/
.header-menu {
  display: flex;
  align-items: center;
  margin: auto;
}

.header-menu-item {
  min-width: 95px;
  height: 60px;
  text-align: center;
  position: relative;
}

.header-menu-item a {
  padding: 12px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #545454;
}

.header-menu-item a:hover {
  color: gray;
}

.header-search-app {
  width: 10%;
  margin-left: 40%;
}

.author-count {
  margin: 10px auto;
  width: 240px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: flex;
}

.author-count-item {
  font-size: 15px;
  width: 80px;
  text-align: center;
  color: white;
}

.author-count-item a {
  color: white;
}

.iconfont {
  font-size: 20px;
}

/** 头部用户信息样式 **/
.header-right {
  display: flex;
}
</style>
