<template>
  <el-dialog
      :visible.sync="this.$store.state.loginDialogVisible"
      :show-close="false"
      class="login-dialog"
      :width="isMobile ? '350px' : '500px'"
      style="margin-top: 10vh;">
    <el-form ref="userLogin" :model="userLogin" :rules="userLoginRules" class="login-box">
      <h3 class="login-title">欢迎登陆</h3>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="userLogin.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="userLogin.password"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="captcha">
        <el-input
            v-model="userLogin.captcha"
            @keyup.enter.native="login"
        >
        </el-input>
        <div class="login-code">
          <img :src="'data:image/jpeg;base64,' + this.captchaBase64" @click="getCaptcha" class="login-code-img"/>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="login">确 定</el-button>
      <el-button @click="resetForm('userLogin')">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {login, getUserInfo} from "@/api/user";
import {getCaptcha} from "@/api/index";
import {setToken, setLocalUserInfo} from "@/utils/auth";
import {isMobile} from "@/utils/mobile";

export default {
  name: 'login',
  data: function () {
    return {
      isMobile: isMobile(),
      windowWidth: document.body.clientWidth,
      captchaBase64: '',
      userLogin: {
        username: '',
        password: '',
        captcha: '',
        uuid: '',
        rememberMe: false
      },
      userLoginRules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    };
  },
  created() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    }, true);
    this.getCaptcha();
  },
  watch: {
    // 监听页面宽度
    windowWidth() {
      this.isMobile = isMobile();
    },
  },
  methods: {
    getCaptcha() {
      getCaptcha().then(res => {
        this.captchaBase64 = res.data.img;
        this.userLogin.uuid = res.data.uuid;
      })
    },
    resetForm() {
      this.$refs.userLogin.resetFields();
      this.$store.dispatch('loginDialogVisibleAction', false);
    },
    login() {
      this.$refs.userLogin.validate((valid) => {
        if (!valid) {
          return false;
        }

        login(this.userLogin.username, this.userLogin.password, this.userLogin.captcha, this.userLogin.uuid).then(res => {
          setToken(res.data);
          getUserInfo().then(res1 => {
            setLocalUserInfo(res1.data);
          });
          this.resetForm();
          this.$message.success("登录成功");
        })
      });
    },
    handleClose() {
      this.resetForm();
    }
  }
};
</script>

<style scoped lang="scss">
.login-box {
}

.login-title {
  font-size: 18px;
  font-weight: revert;
  text-align: center;
}

.login-code {
  width: 80px;
  height: 32px;
  top: 32px;
  right: 0;
  position: absolute;
}

.login-code-img {
  width: 80px;
  height: 32px;
  cursor: pointer;
  vertical-align: middle;
}
</style>
